import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useBlocker, Location } from 'react-router-dom';
import { LeaveSiteModal } from 'app/components/Modals/LeaveSiteModal';

interface ILocation {
  currentLocation: Location;
  historyAction: string;
  nextLocation: Location;
}

export function useCallbackPrompt(when: boolean) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<ILocation | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      if (
        when &&
        !confirmedNavigation &&
        nextLocation.nextLocation.pathname !== location.pathname &&
        !showPrompt
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return true; // Block navigation
      }
      if (confirmedNavigation) {
        setShowPrompt(false);
      }
      return false; // Allow navigation
    },
    [when, confirmedNavigation, location, showPrompt],
  );

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation?.nextLocation?.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  // Block navigation when `when` is true
  useBlocker(handleBlockedNavigation);

  return { showPrompt, confirmNavigation, cancelNavigation };
}

export const RouterPrompt = ({ when }: { when: boolean }) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(when);

  return (
    <>
      {showPrompt && (
        <LeaveSiteModal
          open={showPrompt}
          onConfirm={() => {
            confirmNavigation();
          }}
          onClose={() => {
            cancelNavigation();
          }}
        />
      )}
    </>
  );
};
