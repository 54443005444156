import { ReactNode, useEffect, useRef } from 'react';
import { SettingsViewWrapper } from 'app/pages/RestaurantSettings/_components/SettingsViewWrapper';
import { FormProvider } from 'react-hook-form';
import { SettingHeader } from 'app/components/SettingHeader';
import { RouterPrompt } from 'app/components/RouterPrompt';
import { MessageVariants } from 'enums/notifications';
import { enqueueSnackbar } from 'notistack';
import { ProgressOverlay } from 'app/components/Overlays/ProgressOverlay';

interface OwnProps {
  children: ReactNode;
  methods?: any;
  onSubmit?: any;
  title: string;
  successMessage?: string;
  additionalElements?: ReactNode;
  withoutSaveButton?: boolean;
  isLoading?: boolean;
  isBackArrow?: boolean;
  onBack?: () => void;
}

export const SettingsFormWrapper = ({
  children,
  methods,
  onSubmit,
  title,
  successMessage,
  additionalElements,
  withoutSaveButton,
  isLoading = false,
  isBackArrow,
  onBack,
}: OwnProps) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (methods?.formState?.errors?.root) {
      enqueueSnackbar(methods.formState.errors.root, { variant: MessageVariants.Error });
    }
  }, [methods?.formState?.errors?.root]);

  useEffect(() => {
    const isSubmitSuccessful = methods?.formState?.errors?.isSubmitSuccessful;
    const reset = methods?.reset;

    if (isSubmitSuccessful && successMessage && reset) {
      enqueueSnackbar(successMessage, { variant: MessageVariants.Success });
      reset({}, { keepValues: true });
    }
  }, [methods?.formState?.errors?.isSubmitSuccessful, successMessage]);

  return (
    <SettingsViewWrapper scrollRef={scrollRef}>
      <RouterPrompt when={methods?.formState?.isDirty} />
      <FormProvider {...methods}>
        {withoutSaveButton ? (
          <>
            <SettingHeader
              scrollRef={scrollRef}
              title={title}
              isBackArrow={isBackArrow}
              onBack={onBack}
              additionalElements={additionalElements}
              withoutSaveButton={withoutSaveButton}
              isDirty={methods?.formState?.isDirty}
            />
            {children}
          </>
        ) : (
          <form onSubmit={onSubmit}>
            <SettingHeader
              scrollRef={scrollRef}
              title={title}
              isBackArrow={isBackArrow}
              onBack={onBack}
              additionalElements={additionalElements}
              withoutSaveButton={withoutSaveButton}
              isDirty={methods?.formState?.isDirty}
            />
            {children}
          </form>
        )}

        <ProgressOverlay isLoader={true} open={isLoading} />
      </FormProvider>
    </SettingsViewWrapper>
  );
};
