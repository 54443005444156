import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

type Props = {
  withSpacing?: boolean;
};

export const StyledCard = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'withSpacing',
})<Props>(({ theme, withSpacing }) => ({
  backgroundColor: theme.palette.brandWhite,
  padding: theme.spacing(3),
  borderRadius: theme.borderRadius.small,
  gap: withSpacing ? theme.spacing(4) : 0,
  '& .MuiDivider-root': {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
  },
}));
